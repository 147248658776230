import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import platzhalter_stehtisch from '../images/placeholder_stehtisch.jpeg'
import platzhalter_bierzeltgarnitur from '../images/placeholder_bierzeltgarnitur.jpg'
import platzhalter_beamer from '../images/placeholder_beamer.jpeg'

function Equipment() {
  // State für jede Karte separat
  const [expandedCards, setExpandedCards] = useState({});

  const toggleCard = (id) => {
    setExpandedCards(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const equipment = [
    {
      id: 1,
      title: 'Stehtische',
      images: [platzhalter_stehtisch],
      teasertext: 'Elegante Stehtische mit optionalen weißen Hussen - perfekt für Empfänge und Feiern.',
      description: 'Unsere hochwertigen Stehtische sind die ideale Ergänzung für Ihre Veranstaltung. Mit ihrer weißen Tischplatte und den dezenten dunkelgrauen Beinen setzen sie elegante Akzente. Die optionalen weißen Hussen verleihen jedem Event eine besonders festliche Note.',
      technicalData: {
        material: 'Kunststoff/Metall-Konstruktion',
        durchmesser: '72 cm',
        höhe: '110 cm',
        farbe: 'Weiße Tischplatte, dunkelgraue Beine',
        transport: 'Klappbar und leicht zu transportieren'
      },
      prices: [
        'Preis pro Stehtisch und Tag (24h): 5 Euro',
        'Wochenendpreis (Fr-Mo): 12 Euro',
        'Mit Husse: +5 Euro pro Tisch (inkl. Reinigung)',
        'Kaution pro Tisch: 20 Euro'
      ],
      optional: [
        'Hochwertige weiße Hussen',
        'Lieferung/Abholung: Preis auf Anfrage',
      ],
      packMass: [
        'Kompakt zusammenklappbar',
        'Problemlos in PKWs transportierbar'
      ],
      additionalInfo: 'Die Tische überzeugen durch ihre einfache Handhabung und ihr zeitloses Design. Nach der Veranstaltung übernehmen wir die professionelle Reinigung der Hussen.'
    },
    {
      id: 2,
      title: 'Bierzeltgarnituren',
      images: [platzhalter_bierzeltgarnitur],
      teasertext: 'Moderne schwarze Bierzeltgarnituren - der Klassiker für entspanntes Feiern.',
      description: 'Unsere modernen Bierzeltgarnituren in zeitlosem Schwarz sind der perfekte Partner für jede Veranstaltung. Ein Set besteht aus einem stabilen Tisch und zwei passenden Bänken. Die leichte und dennoch robuste Bauweise ermöglicht einen schnellen Auf- und Abbau.',
      technicalData: {
        material: 'Kunststoff/Metall-Konstruktion',
        tischmaße: '180x74cm, Höhe: 74cm',
        bankmaße: '180x30cm, Höhe: 43cm',
        farbe: 'Schwarz',
        transport: 'Klappbar und leicht zu transportieren'
      },
      prices: [
        'Preis pro Set und Tag (24h): 10 Euro',
        'Wochenendpreis (Fr-Mo): 24 Euro',
        'Kaution pro Set: 50 Euro'
      ],
      optional: [
        'Lieferung/Abholung: Preis auf Anfrage',
      ],
      packMass: [
        'Tisch geklappt: 90x74x9cm',
        'Bänke je: 90x30x9cm',
        'Kompakt verstaubar'
      ],
      additionalInfo: 'Die Sets bestechen durch ihr modernes Design und ihre praktische Handhabung. Die schwarze Ausführung verleiht ihnen einen zeitgemäßen Look und macht sie zum perfekten Begleiter für Ihre Veranstaltung.'
    },
    {
      id: 3,
      title: '4K Beamer',
      images: [platzhalter_beamer],
      teasertext: 'Hochmoderner 4K Triple-Laser Beamer mit integriertem Android TV - perfekt für Präsentationen und Entertainment.',
      description: 'Der JMGO N1 Ultra ist ein State-of-the-Art 4K Beamer mit Triple-Laser Technologie. Mit seinen integrierten Dynaudio Lautsprechern, Smart TV Funktionen und automatischer Ausrichtung bietet er maximalen Komfort bei höchster Bildqualität. Das integrierte Gimbal ermöglicht eine flexible Positionierung, während die automatische Anpassung an die Projektionsfläche für ein perfektes Bild sorgt.',
      technicalData: {
        modell: 'JMGO N1 Ultra',
        auflösung: '4K',
        helligkeit: '4000 ANSI Lumen (2200 CVIA Lumen)',
        kontrast: '1600:1',
        projektionsgröße: '60 bis 180 Zoll (120 Zoll empfohlen)',
        lautsprecher: '2x 10W Dynaudio',
        ausrichtung: 'Gimbal (360° horizontal, 135° vertikal)',
        extras: 'HDR10, WiFi, Bluetooth, Android TV',
        anschlüsse: '2x HDMI 2.1, USB-A, 3.5mm Kopfhörer'
      },
      prices: [
        'Preis pro Tag (24h): 30 Euro',
        'Wochenendpreis (Fr-Mo): 70 Euro',
        'Kaution: 300 Euro'
      ],
      optional: [
        'Lieferung/Abholung: Preis auf Anfrage'
      ],
      packMass: [
        'Transportcase: XxXxXcm',
      ],
      additionalInfo: 'Der Beamer wird im hochwertigen Transportcase geliefert und ist dank automatischer Ausrichtung und Smart TV Funktionen besonders benutzerfreundlich. Die Triple-Laser Technologie sorgt für brillante Farben und gestochen scharfe Bilder.'
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Mobiliar & Equipment mieten - Bierzeltgarnituren & Stehtische | Eventservice Taeger</title>
        <meta name="description" content="Mieten Sie hochwertiges Mobiliar für Ihre Veranstaltung. Bierzeltgarnituren und Stehtische in bester Qualität - flexibel und günstig bei Eventservice Taeger." />
        <meta name="keywords" content="Bierzeltgarnitur mieten, Stehtisch mieten, Eventmobiliar, Festmobiliar, Partymöbel, Herford, Bielefeld" />
        <link rel="canonical" href="https://eventservice-taeger.de/equipment" />
      </Helmet>

      <div className="container mx-auto px-6 py-6 text-center">
        <h1 className="text-black text-4xl font-bold mb-4">Equipment & Mobiliar</h1>
        <p className="text-black text-xl">
          Das passende Mobiliar für jede Veranstaltung - hochwertig und flexibel.
        </p>
      </div>

      <div className="container mx-auto px-6 py-6">
        <div className="space-y-8">
          {equipment.map((item) => (
            <div key={item.id} className="border rounded-lg shadow-md overflow-hidden">
              <div className="flex flex-col md:flex-row">
                {/* Linke Seite mit Bild */}
                <div className="w-full md:w-2/5">
                  <div className="relative bg-white p-4">
                    <div className="image-4by3 w-full relative pb-[50%] overflow-hidden">
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="absolute top-0 left-0 w-full h-full object-contain"
                        width="480"
                        height="360"
                      />
                    </div>
                  </div>
                </div>

                {/* Rechte Seite mit Informationen */}
                <div className="w-full md:w-3/5 p-6">
                  <div className="flex flex-col">
                    {/* Basis-Informationen */}
                    <div>
                      <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                      <p className="text-gray-700 mb-2">{item.teasertext}</p>
                      <button
                        onClick={() => toggleCard(item.id)}
                        className="text-blue-600 hover:underline text-lg mb-4"
                      >
                        {expandedCards[item.id] ? 'Weniger anzeigen' : 'Mehr erfahren'}
                      </button>
                    </div>

                    {/* Erweiterte Informationen */}
                    {expandedCards[item.id] && (
                      <div className="space-y-6">
                        <div>
                          <h3 className="text-xl font-bold mb-2">Beschreibung</h3>
                          <p className="text-gray-700">{item.description}</p>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Technische Daten</h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            {Object.entries(item.technicalData).map(([key, value], index) => (
                              <div key={index} className="text-gray-700">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{' '}
                                {value}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Preise</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {item.prices.map((price, index) => (
                              <li key={index}>{price}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Optionales Zubehör</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {item.optional.map((option, index) => (
                              <li key={index}>{option}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Packmaß</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {item.packMass.map((mass, index) => (
                              <li key={index}>{mass}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="flex space-x-4">
                          <a
                            href="/kontakt"
                            className="bg-gray-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-gray-700"
                          >
                            Kontakt aufnehmen
                          </a>
                          <a
                            href={`https://wa.me/4915164696160?text=Ich%20interessiere%20mich%20für%20${encodeURIComponent(
                              item.title
                            )}.`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-green-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-green-700"
                          >
                            Per WhatsApp kontaktieren
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="border rounded-lg shadow-md p-6 text-center mt-8">
          <h2 className="text-2xl font-bold mb-4">Wir erweitern unser Angebot!</h2>
          <p className="text-gray-700 mb-4">
            Unser Angebot wird stetig erweitert. Schauen Sie gerne in Kürze wieder vorbei – wir arbeiten kontinuierlich daran, 
            unser Equipment-Sortiment zu erweitern, um Ihre Veranstaltung noch perfekter zu machen!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Equipment;