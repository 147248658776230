import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function UeberUns() {
  // Funktion zum Menü schließen und zum Seitenanfang scrollen
  const handleLinkClick = () => {
    // Menü schließen, falls Sie ein solches verwenden
    // setIsOpen(false); // Menü schließen
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollt zum Anfang der Seite
  };

  return (
    <div>
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Über Uns - Eventservice Taeger | Partyzelte & Cocktailmaschine HUGO</title>
        <meta
          name="description"
          content="Lernen Sie Eventservice Taeger kennen: Wir machen Feiern für jeden möglich – unabhängig vom Budget."
        />
        <meta
          name="keywords"
          content="Eventservice, Partyzelte, Cocktailmaschine, Herford, OWL, Niedersachsen"
        />
        <link rel="canonical" href="https://eventservice-taeger.de/ueber-uns" />
      </Helmet>

      {/* Überschrift und Untertitel */}
      <div className="container mx-auto px-6 py-6 text-center">
        <h1 className="text-black text-4xl font-bold mb-4">Über Uns</h1>
        <p className="text-black text-xl">
          Feiern für jeden möglich machen – unabhängig vom Budget.
        </p>
      </div>

      <div className="container mx-auto px-6 py-6">
        <div className="space-y-8 text-left">
          {/* Willkommen bei Eventservice Taeger */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">
              Willkommen bei Eventservice Taeger!
            </h2>
            <p className="text-gray-700 mb-4">
              Wir kennen es selbst: Die Planung einer gelungenen Feier kann
              schnell teuer und aufwendig werden. Bei unserer eigenen Hochzeit
              haben wir festgestellt, wie die Kosten für Zelte und Ausstattung
              in die Höhe schießen können. Da dachten wir uns, das muss auch
              anders gehen!
            </p>
            <p className="text-gray-700 mb-4">
              Deshalb haben wir beschlossen, Feiern für jeden möglich zu machen
              – unabhängig vom Budget. Wir möchten, dass Sie in gemütlicher
              Atmosphäre feiern können, ohne dabei tief in die Tasche greifen zu
              müssen.
            </p>
          </div>

          {/* Was wir anbieten */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Was wir anbieten</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Cocktailmaschine HUGO:</strong> Unsere selbst
                entwickelte Cocktailmaschine mit digitaler Cocktailkarte bringt
                Spaß und Abwechslung auf jede Party. Sie ist nicht nur günstiger
                als ein professioneller Barkeeper, sondern bietet auch mehr
                Unterhaltung, weil Ihre Gäste die Drinks selbst auswählen
                können.
              </li>
              <li>
                <strong>Partyzelte zum Selberaufbau:</strong> Wir bieten Zelte
                in der Größe 5×8 Meter an. Sie können das Zelt selbst aufbauen
                oder unseren Aufbauservice nutzen – ganz wie Sie möchten.
              </li>
              <li>
                <strong>Stehtische und Bierzeltgarnituren:</strong> Leichte,
                klappbare Tische und Garnituren, die einfach zu transportieren
                und aufzubauen sind. Auf Wunsch auch mit Hussen für eine
                elegante Optik.
              </li>
            </ul>
          </div>

          {/* Warum Eventservice Taeger */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">
              Warum Eventservice Taeger?
            </h2>
            <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Faire Preise:</strong> Als Kleinunternehmer können wir
                Ihnen günstige Preise anbieten, da wir keine Umsatzsteuer
                erheben – das ist besonders für Privatpersonen vorteilhaft.
              </li>
              <li>
                <strong>Persönlicher Service:</strong> Wir legen großen Wert auf
                freundliche und zuverlässige Kommunikation und sind auch
                kurzfristig für Sie da.
              </li>
              <li>
                <strong>Flexibilität:</strong> Wir erweitern ständig unser
                Angebot und passen uns Ihren Wünschen an.
              </li>
            </ul>
          </div>

          {/* Kontaktieren Sie uns */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Kontaktieren Sie uns</h2>
            <p className="text-gray-700 mb-4">
              Haben Sie Fragen oder besondere Wünsche? Rufen Sie uns einfach an
              oder schreiben Sie uns.{' '}
              <Link to="/kontakt" className="text-blue-500" onClick={handleLinkClick}>
                Hier geht's zum Kontaktformular
              </Link>
              . Wir freuen uns darauf, von Ihnen zu hören!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UeberUns;
